///
/// @file  _toofaced-base-print.scss
///
/// \brief Pull all parts of the theme scss together into one file
///

// re-declaring variables as _print.scss could be imported on its own
$text-direction: ltr !default;
$rdirection: right !default;
$ldirection: left !default;

@media print {
  .site-content {
    width: 90%;
    margin: 0 auto;
  }

  /* ==========================================================================
     Global
     ========================================================================== */

  // Drupal _toolbar (this is only for lower environments)
  #toolbar-trigger {
    display: none;
  }

  // header, footer, back to top button
  .site-header,
  .footer,
  .back-to-top {
    display: none;
  }

  /* ==========================================================================
     Components
     ========================================================================== */

  // _account_nav
  .account-section__nav {
    display: none;
  }
  //_buttons
  %btn {
    display: none;
  }
}
